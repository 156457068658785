import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import 'react-h5-audio-player/lib/styles.css';

import { Layout, Row, Col, List, Spin, Card, Button, Modal, Form, Input, Comment, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from '../../../config/firebase';
import Config from "../../config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import * as AuthActions from "../../redux/actions/auth-actions";

const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const { Header, Sider, Content } = Layout;


const dateFormat = "Do MMM";

class AllAdverseEvents extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      collapsed: false, 
      patientData: undefined, 
      loading: true, 
      formKey: 0, 
      isModalVisible: false, 
      submitted: false, 
      patientVoiceDiary: [], 
      selectItemId: "", 
      selectedCol: "adverseEvents", 
      adverseEvent: undefined, 
      imageUrl: undefined, 
      patientId: undefined, 
      detailLoading: false, 
      roleName: undefined, 
      adverseEvents: undefined,
      adverseEventDetail: [],
      docComments: [],
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });

    await this.props
      .getLoggedInUser()
      .then((resp) => {
        this.setState({
          roleName: resp.data.roles[0].name,
        });

      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    if (this.state.roleName && this.state.roleName === "PI_USER") {
      await this.props.listPIAdverseEvents();
      this.setState({ adverseEvents: this.props.listPiAdverseEvents })
      var defaultEvent = this.props.listPiAdverseEvents[0];
      // await this.props.getAdverseEventDetails(defaultEvent.adverseEventId);
      this.setState({ detailLoading: true })
      await this.props.reviewAdverseEvents(studyId, defaultEvent.adverseEventId, defaultEvent.patientId);
      await this.props.getDoctorCommentsOnAdverseEvents(defaultEvent.adverseEventId);
      this.setState({  adverseEventDetail: this.props.adverseEventDetail, selectItemId: defaultEvent.adverseEventId, adverseEvent: defaultEvent, patientId: defaultEvent.patientId, docComments: this.props.doctorComments, detailLoading: false })
    }
    else {
      await this.props.listAllAdverseEvents(studyId);
      if (this.props.adverseEventsList) {
        this.setState({ adverseEvents: this.props.adverseEventsList })
        var defaultEvent = this.props.adverseEventsList[0];
        // await this.props.getAdverseEventDetails(defaultEvent.adverseEventId);
        this.setState({ detailLoading: true })
        await this.props.reviewAdverseEvents(studyId, defaultEvent.adverseEventId, defaultEvent.patientId);
        await this.props.getDoctorCommentsOnAdverseEvents(defaultEvent.adverseEventId);
        this.setState({  adverseEventDetail: this.props.adverseEventDetail, selectItemId: defaultEvent.adverseEventId, adverseEvent: defaultEvent, patientId: defaultEvent.patientId, docComments: this.props.doctorComments, detailLoading: false })
      }
    }

  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  addDoctorNotesOnAdverseEvents = async (values) => {
    this.setState({ formKey: (this.state.formKey || 0) + 1 })

    let self = this;
    if (self.state.eventId === "" || self.state.eventId === null) {
      notifyUser("Please select any event", "error");
    }
    else {
      let data = {
        doctorNote: values.doctorNotes,
        // patientId: this.props.match.params.id,
        eventId: this.state.selectItemId
      };

      this.setState({ submitted: true });
      await self.props
        .addDoctorNotesOnAdverseEvents(data)
        .then((response) => {
          if (response.error && response.error !== "") {
            notifyUser(response.error.message, "error");
            self.setState({ submitted: false });
          } else {
            if (response.data && response.data !== "") {
              notifyUser("Doctor note added Successfully", "success");
              self.setState({ submitted: false });
            }
          }
        });
        await this.props.getDoctorCommentsOnAdverseEvents(this.state.selectItemId);
        this.setState({docComments: this.props.doctorComments});
        
    }
  };
  goToPISignOff = () => {
    this.props.history.push("/adverse-event-signOff/" + this.state.patientId + "/" + this.state.selectItemId);
  }

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref.where(`users.${patientId + type}`, '==', true).get().then
      (docs => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        })
        this.props.history.push("/chat/" + patientId);
      }
      )
  }

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId })
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  }

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id })
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  }
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  }

  goToDashboard = () => {
    this.props.history.push("/dashboard");
  }

  goToActivityData = (id) => {
    this.setState({ selectedCol: id })
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  }

  async goToAdverseEvent(item) {
    let studyId = localStorage.getItem("studyId");
    this.setState({ detailLoading: true })
    await this.props.reviewAdverseEvents(studyId, item.adverseEventId, item.patientId)
    this.setState({  adverseEventDetail: this.props.adverseEventDetail, selectItemId: item.adverseEventId, adverseEvent: item, patientId: item.patientId, detailLoading: false })
    await this.props.getDoctorCommentsOnAdverseEvents(item.adverseEventId);
  }

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  }

  goToAdverseEventLogs = () => {
    this.props.history.push("/event-logs/" + this.state.selectItemId);
  }

  formatDate = (timestamp) => {
    // Ensure the timestamp is a number
    const date = new Date(Number(timestamp));
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error('Invalid Date:', date);
      return 'Invalid Date';
    }

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  render() {
    const { submitted, selectItemId, adverseEvent, detailLoading, adverseEventDetail, docComments } = this.state;
    const studyId = localStorage.getItem("studyId");
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card title={this.state.data && this.state.data.title }>

              <Spin spinning={submitted}>
                {!this.state.loading &&
                  <Row gutter={24}>

                    <Col xs={24} sm={24} md={24} xl={24}>
                      <Card
                        title={"Adverse Events"}
                        extra={<Button onClick={() => this.goToDashboard()}>Back</Button>}
                        style={{ marginBottom: '8px', padding: '0 16px' }}
                        className="patient-dairy-section"
                      >
                        {this.state.adverseEvents ? <Row gutter={24}>
                          <Col xs={24} sm={12} md={8} xl={8}>
                            {/* Activity Tabs START */}
                            <div className="card-container">

                              <List
                                itemLayout="horizontal"
                                dataSource={this.state.adverseEvents}
                                renderItem={item => (
                                  <List.Item onClick={() => this.goToAdverseEvent(item)} className={
                                    selectItemId === item.adverseEventId
                                      ? "selected-item-list"
                                      : "item-list"
                                  }>
                                    <List.Item.Meta
                                      avatar={<span className="date">{item.date ? moment(item.date).format(dateFormat) : "date"}</span>}

                                      description={item.patientName || "N/A"}
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                            {/* /Activity Tabs END */}
                          </Col>
                          <Col xs={24} sm={12} md={16} xl={16}>
                            {/* Activity Content Section Start */}
                            <div className="dairy-content-section">
                              <div className="play-icon-container">
                              </div>
                              <Spin spinning={detailLoading}>
                                {adverseEvent ? <Form
                                  layout="vertical"
                                  key={this.state.formKey}
                                  onFinish={this.addDoctorNotesOnAdverseEvents}
                                >
                                  <List
                                    itemLayout="horizontal"
                                    className="screening-ques"
                                    dataSource={adverseEventDetail}
                                    renderItem={(quesAnsData, index) => (
                                      <>
                                        <List.Item >
                                          <List.Item.Meta
                                            title={<span><b>{"Q" + (index + 1) + ". "}</b> {quesAnsData.question}</span>}
                                            description={<span><b>{"A" + (index + 1) + ". "}</b> {quesAnsData.question.toLowerCase().includes('date') ? 
                                              // moment(Date(Number(quesAnsData.answer))).format("DD/MM/YYYY")
                                              this.formatDate(quesAnsData.answer)
                                              :quesAnsData.answer}</span>}
                                          />
                                          <>

                                          </>
                                        </List.Item>

                                      </>
                                    )}
                                  />
                                  {
                                    this.props.adversePhotoUrl && this.props.adversePhotoUrl.length > 0 ?
                                    <List grid={{ gutter: 8, column: 5 }}
                                    itemLayout="verticle"
                                    dataSource={this.props.adversePhotoUrl}
                                    renderItem={img => (
                                      <div>
                                        <List.Item>
                                          <img onClick={() => this.showModal(img)} width={130} src={img.url} />
                                          <Typography.Text >{img.desc}</Typography.Text>
                                        </List.Item>

                                        <Modal centered width={450} className="photo-zoom-popup" visible={this.state.isModalVisible} onCancel={this.handleCancel} footer={[<></>,]}>

                                          <TransformWrapper
                                            defaultScale={1}
                                            defaultPositionX={1}
                                            defaultPositionY={1}
                                          >
                                            {({ zoomIn, zoomOut }) => (
                                              <>
                                                <PlusCircleFilled size={30} onClick={zoomIn} />
                                                <MinusCircleFilled size={20} onClick={zoomOut} />
                                                <TransformComponent>
                                                  <img width={400} src={this.state.image.url} alt={img.desc} />
                                                </TransformComponent>
                                              </>
                                            )}
                                          </TransformWrapper>

                                        </Modal>
                                      </div>
                                    )}
                                  /> : ""
                                  }

                                  {this.props.doctorComments ?
                                    <div className="previous-comment-section" >
                                      <Form.Item label="Previous Comments" >
                                        <div style={{ maxHeight: "300px", overflow: "auto" }}>
                                          {docComments.map((comment) => {
                                            return <div >
                                              <Comment
                                                author={comment.doctorName}
                                                content={
                                                  comment.doctorNote
                                                }
                                                datetime={
                                                  <Tooltip >
                                                    {moment(comment.noteDate).format(dateFormathh)}
                                                  </Tooltip>
                                                }
                                              />
                                            </div>
                                          }
                                          )}
                                        </div>
                                      </Form.Item></div> : ""}

                                  <Form.Item
                                    name="doctorNotes"
                                    label="Doctor Notes"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please add comment!",
                                        whitespace: true
                                      },
                                    ]} >
                                    <Input.TextArea placeholder="Add comment..." />
                                  </Form.Item>

                                  <Form.Item className="btn-form-container">
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                    <Button type="primary" onClick={() => this.goToPISignOff()}>PI SignOff</Button>
                                    <Button type="primary" onClick={() => this.goToAdverseEventLogs()}>Event Logs</Button>
                                  </Form.Item>
                                </Form> : ""}
                              </Spin>
                            </div>

                            {/* /Activity Content Section End */}
                          </Col>
                        </Row> : ""}
                      </Card>
                    </Col>
                  </Row>
                }
              </Spin>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    adverseEventsList: state.adverseEvents.listAdverseEvents,
    listPiAdverseEvents: state.adverseEvents.listPiAdverseEvents,
    doctorComments: state.adverseEvents.doctorNotesList,
    adversePhotoUrl: state.adverseEvents.adversePhotoUrl,
    adverseEventDetail: state.adverseEvents.adverseEventDetail
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...StudyActions, ...AdverseEventActions, ...AuthActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AllAdverseEvents
  )
);
