import React, { Component, useDebugValue } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions";
import * as EasiScoreActions from "../../redux/actions/easi-scores-actions";
import "react-h5-audio-player/lib/styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  AuditOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Radio,
  Carousel,
  Modal,
  Form,
  Input,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";
import { getLoggedInUser } from "../../redux/actions/auth-actions.js";
import photoGallery from "../photoGallery/photo-gallery.jsx";
import { MedicationIconSvg } from "../shared/svg/medication";
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);
const ButtonGroup = Button.Group;

class FlareHead extends Component {
  constructor(props) {
    const headImages = (props.flareImages || []).filter(image => image.category === "head");
    console.log('headImages',headImages);
    
    super(props);
    this.state = {
      collapsed: false,
      image: {},
      patientData: undefined,
      photoGallery: undefined,
      photoGalleryByWeek: undefined,
      loading: true,
      submitted: false,
      submitAreaScore: false,
      patientVoiceDiary: [],
      flarePhotos: [],
      selectItemId: "",
      selectedMonth: 1,
      selectedWeek: 1,
      selectedMonthNih: 1,
      selectedBodyPart: "Head",
      bodypartType: "HEAD",
      bodypartTypeByWeek: "HEAD_AND_NECK",
      partTypeNih: "HEAD_AND_NECK",
      easiPartType: "Head",
      globalAssessment: undefined,
      range: "",
      edema: "",
      excoriation: "",
      induration: "",
      lichenification: "",
      ascoScope: "",
      erythema: "",
      scaling: "",
      edemaValue: undefined,
      excoriationsValue: undefined,
      erythemaValue: undefined,
      ascoScopeValue: undefined,
      indurationValue: undefined,
      lichenificationValue: undefined,
      scalingValue: undefined,
      initialSlide: 0,
      areaScoreValue: undefined,
      isDisabled: false,
      currentImageIndex: 0,
      scoreDate: "",
      // rotationAngles: (props.photoGalleryByWeek && props.photoGalleryByWeek.length) ? props.photoGalleryByWeek.map(() => 0) : [],
      rotationAngleState: "",
      rotationAngles: Array(this.props.flareImages && this.props.flareImages.length).fill(0),
      // rotationAngles: Array(headImages.length).fill(0),
    };
  }

  async componentDidMount() {
    
    this.setState({ submitted: true, submitAreaScore: true, loading: true });
    // this.setState({ isDisabled: true });
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          //loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    await this.props.getPatientByIdNih(patientId);
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      // this.setState({ loading: false })
    }
    let date = this.props.match.params.date;

    let photoData = await this.props.getAllFlareImages(patientId, date);

    console.log("flareImg", this.props.flareImages);

    await this.setState({ flarePhotos: photoData, scoreDate: date });
    let monthnih = this.state.monthnih;
    let pasiScores = undefined;
    pasiScores = await this.props.getPartsTotalScore(patientId, date, "Head")
    console.log('flarePasEasScores',this.props.flarePasEasScores);
    console.log('pasiScores',pasiScores);
    

    if (pasiScores && pasiScores.data) {
      this.setState({ easiScores: pasiScores.data, areaScoreValue: pasiScores.data && pasiScores.data.areaScore });
    }

    let localErythmaValue = localStorage.getItem("localErythmaValueHead" + monthnih + patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueHead" + monthnih + patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueHead" + monthnih + patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueHead" + monthnih + patientId);
    let localScalingValue = localStorage.getItem("localScalingValueHead" + monthnih + patientId);

    const patientData = this.props.patientData;

    if (patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS") {
      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "None" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 0, scaling: "None" });
      } else if (localScalingValue === "None") {
        this.setState({ scalingValue: 0, scaling: "None" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Slight" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 1, scaling: "Slight" });
      } else if (localScalingValue === "Slight") {
        this.setState({ scalingValue: 1, scaling: "Slight" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Moderate" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 2, scaling: "Moderate" });
      } else if (localScalingValue === "Moderate") {
        this.setState({ scalingValue: 2, scaling: "Moderate" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Severe" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 3, scaling: "Severe" });
      } else if (localScalingValue === "Severe") {
        this.setState({ scalingValue: 3, scaling: "Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.scaling &&
        this.state.easiScores.scaling != null &&
        this.state.easiScores.scaling === "Very_Severe" &&
        (localScalingValue === null || localScalingValue === undefined)
      ) {
        this.setState({ scalingValue: 4, scaling: "Very_Severe" });
      } else if (localScalingValue === "Very_Severe") {
        this.setState({ scalingValue: 4, scaling: "Very_Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "None" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 0, erythema: "None" });
      } else if (localErythmaValue === "None") {
        this.setState({ erythemaValue: 0, erythema: "None" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Slight" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1, erythema: "Slight" });
      } else if (localErythmaValue === "Slight") {
        this.setState({ erythemaValue: 1, erythema: "Slight" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      } else if (localErythmaValue === "Moderate") {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      } else if (localErythmaValue === "Severe") {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Very_Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 4, erythema: "Very_Severe" });
      } else if (localErythmaValue === "Very_Severe") {
        this.setState({ erythemaValue: 4, erythema: "Very_Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "None" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 0, induration: "None" });
      } else if (localIndurationValue === "None") {
        this.setState({ indurationValue: 0, induration: "None" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Slight" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1, induration: "Slight" });
      } else if (localIndurationValue === "Slight") {
        this.setState({ indurationValue: 1, induration: "Slight" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      } else if (localIndurationValue === "Moderate") {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 3, induration: "Severe" });
      } else if (localIndurationValue === "Severe") {
        this.setState({ indurationValue: 3, induration: "Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Very_Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 4, induration: "Very_Severe" });
      } else if (localIndurationValue === "Very_Severe") {
        this.setState({ indurationValue: 4, induration: "Very_Severe" });
      }
    } else {
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "None" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 0, erythema: "None" });
      } else if (localErythmaValue === "None") {
        this.setState({ erythemaValue: 0, erythema: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Mild" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1, erythema: "Mild" });
      } else if (localErythmaValue === "Mild") {
        this.setState({ erythemaValue: 1, erythema: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Mild_Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
      } else if (localErythmaValue === "Mild_Moderate") {
        this.setState({ erythemaValue: 1.5, erythema: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      } else if (localErythmaValue === "Moderate") {
        this.setState({ erythemaValue: 2, erythema: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Moderate_Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
      } else if (localErythmaValue === "Moderate_Severe") {
        this.setState({ erythemaValue: 2.5, erythema: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.erythema &&
        this.state.easiScores.erythema != null &&
        this.state.easiScores.erythema === "Severe" &&
        (localErythmaValue === null || localErythmaValue === undefined)
      ) {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      } else if (localErythmaValue === "Severe") {
        this.setState({ erythemaValue: 3, erythema: "Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "None" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 0, excoriation: "None" });
      } else if (localExcoriationValue === "None") {
        this.setState({ excoriationsValue: 0, excoriation: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Mild" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 1, excoriation: "Mild" });
      } else if (localExcoriationValue === "Mild") {

        this.setState({ excoriationsValue: 1, excoriation: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Mild_Moderate" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
      } else if (localExcoriationValue === "Mild_Moderate") {

        this.setState({ excoriationsValue: 1.5, excoriation: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Moderate" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
      } else if (localExcoriationValue === "Moderate") {
        this.setState({ excoriationsValue: 2, excoriation: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Moderate_Severe" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
      } else if (localExcoriationValue === "Moderate_Severe") {
        this.setState({ excoriationsValue: 2.5, excoriation: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.excoriation &&
        this.state.easiScores.excoriation != null &&
        this.state.easiScores.excoriation === "Severe" &&
        (localExcoriationValue === null || localExcoriationValue === undefined)
      ) {
        this.setState({ excoriationsValue: 3, excoriation: "Severe" });
      } else if (localExcoriationValue === "Severe") {
        this.setState({ excoriationsValue: 3, excoriation: "Severe" });
      }

      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "None" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 0, induration: "None" });
      } else if (localIndurationValue === "None") {
        this.setState({ indurationValue: 0, induration: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Mild" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1, induration: "Mild" });
      } else if (localIndurationValue === "Mild") {
        this.setState({ indurationValue: 1, induration: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Mild_Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
      } else if (localIndurationValue === "Mild_Moderate") {
        this.setState({ indurationValue: 1.5, induration: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      } else if (localIndurationValue === "Moderate") {
        this.setState({ indurationValue: 2, induration: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Moderate_Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
      } else if (localIndurationValue === "Moderate_Severe") {
        this.setState({ indurationValue: 2.5, induration: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.induration &&
        this.state.easiScores.induration != null &&
        this.state.easiScores.induration === "Severe" &&
        (localIndurationValue === null || localIndurationValue === undefined)
      ) {
        this.setState({ indurationValue: 3, induration: "Severe" });
      } else if (localIndurationValue === "Severe") {
        this.setState({ indurationValue: 3, induration: "Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "None" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 0, lichenification: "None" });
      } else if (localLichenificationValue === "None") {
        this.setState({ lichenificationValue: 0, lichenification: "None" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Mild" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 1, lichenification: "Mild" });
      } else if (localLichenificationValue === "Mild") {
        this.setState({ lichenificationValue: 1, lichenification: "Mild" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Mild_Moderate" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
      } else if (localLichenificationValue === "Mild_Moderate") {
        this.setState({ lichenificationValue: 1.5, lichenification: "Mild_Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Moderate" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
      } else if (localLichenificationValue === "Moderate") {
        this.setState({ lichenificationValue: 2, lichenification: "Moderate" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Moderate_Severe" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
      } else if (localLichenificationValue === "Moderate_Severe") {
        this.setState({ lichenificationValue: 2.5, lichenification: "Moderate_Severe" });
      }
      if (
        this.state.easiScores &&
        this.state.easiScores.lichenification &&
        this.state.easiScores.lichenification != null &&
        this.state.easiScores.lichenification === "Severe" &&
        (localLichenificationValue === null || localLichenificationValue === undefined)
      ) {
        this.setState({ lichenificationValue: 3, lichenification: "Severe" });
      } else if (localLichenificationValue === "Severe") {
        this.setState({ lichenificationValue: 3, lichenification: "Severe" });

      }
    }

    this.setState({ submitted: false, submitAreaScore: false, loading: false });
    // this.setState({ isDisabled: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.photoGalleryByWeek !== this.props.photoGalleryByWeek) {
      this.setState({
        rotationAngles: this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length ? this.props.photoGalleryByWeek.map(() => 0) : [],
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let studyId = localStorage.getItem("studyId");
    if (this.props.easiScores !== nextProps.easiScores) {
      this.setState({ easiScores: nextProps.easiScores });
      this.setState({ photoGallery: nextProps.photoGallery });
      if (studyId === 1) {
        this.setState({ patientSelfEstimation: nextProps.patientSelfEstimation });
      } else {
        this.setState({ patientSelfEstimation: nextProps.patientSelfEstimationJanssen });
      }
      this.setState({ loading: false });
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };

  goToFlarePhotoGallery = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date
    this.props.history.push("/flare-photographs/" + patientId + "/" + taskDate);
  };

  
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToLowerExt = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-lowerExt/" + patientId +"/"+ taskDate);
  };
  goToUpperExt = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-upperExt/" + patientId +"/"+ taskDate);
  };
  goToTrunk = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-trunk/" + patientId +"/"+ taskDate);
  };

  goToReviewTotal = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-total/" + patientId +"/"+ taskDate);
  };

  goToGlobalAssessment = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flareGlobalAssessment/" + patientId +"/"+ taskDate);
  };

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };
  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }


  savePasiScore = async (values) => {
    console.log('this.state.excoriation',this.state.excoriation);
    this.setState({ loading: true });
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    let patientData = this.props.patientData;
    if (
      (this.state.scaling === "" && this.state.easiScores.scaling === "") ||
      (this.state.excoriation === "" &&
        this.state.easiScores.excoriation === "") ||
      (this.state.erythema === "" && this.state.easiScores.erythema === "") ||
      (this.state.induration === "" && this.state.easiScores.induration === "") ||
      (this.state.lichenification === "" && this.state.easiScores.lichenification === "")
    ) {
      notifyUser("Please add head scores", "error");
    } else {
      let areaScore = this.state.areaScoreValue;
      console.log('this.state.erythema',this.state.erythema);
      

      this.setState({ submitted: true });
      let data = {
        erythema: this.state.erythema,
  
        // Conditional logic for the other fields
        ...(patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" 
          ? {
              scaling: this.state.scaling,
              induration: this.state.induration,
            }
          : {
              induration: this.state.induration,
              excoriation: this.state.excoriation,
              lichenification: this.state.lichenification,
            }),
          totalScore: patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ?
            (Math.round(
              ((
                this.state.scalingValue +
                this.state.erythemaValue +
                this.state.indurationValue) *
                areaScore *
                0.1 *
                1000) /
              10
            ) / 100
            ).toFixed(2) :
            (Math.round(
              ((this.state.erythemaValue +
                this.state.indurationValue +
                this.state.excoriationsValue +
                this.state.lichenificationValue) *
                areaScore *
                0.1 *
                1000) /
              10
            ) / 100
            ).toFixed(2),
          month: this.state.monthnih,
          week: this.state.week,
          patientId: patientId,
          bodyPartType: "Head",
          studyId: studyId,
          areaScore: areaScore,
          date: this.state.scoreDate,
          scoreId: this.state.easiScores.scoreId
        }
      console.log("scores", this.state.erythemaValue,
        this.state.indurationValue,
        this.state.excoriationsValue,
        this.state.lichenificationValue,
        this.state.scalingValue);

      console.log('data', data);

      const savedData = await this.props.saveFlarePasiScore(data);
      console.log('savedData',savedData.data);
      
      if (savedData == "" || savedData == undefined || savedData.data == "Total Score does not match" || savedData.data == undefined) {

        notifyUser("EASI score not successfully saved due to missing values", "error");
        // Get all keys from localStorage
        const allKeys = Object.keys(localStorage);

        // Terms to check for
        const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

        // Iterate through keys and remove those containing the specified terms
        allKeys.forEach(key => {
          if (termsToRemove.some(term => key.includes(term))) {
            localStorage.removeItem(key);
          }
        });
        this.setState({loading: false});

      } else {
        console.log('this.props.flareEasiScores',this.props.flareEasiScores);
        
        this.setState(
          { easiScores: savedData.data, loading: false },
          () => {
            notifyUser("Data Saved", "success");
          }
        );
      }
      // }
      // Get all keys from localStorage
      const allKeys = Object.keys(localStorage);

      // Terms to check for
      const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

      // Iterate through keys and remove those containing the specified terms
      allKeys.forEach(key => {
        if (termsToRemove.some(term => key.includes(term))) {
          localStorage.removeItem(key);
        }
      });

      this.setState({ submitted: false });
    }
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  showModalOth = (img, index) => {
    this.setState({
      isModalVisible: true,
      currentImageIndex: index,
      image: img,
    });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, rotationAngleState: "", rotationAngles: Array(this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length).fill(0) });
  };



  handleErythemaValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localErythmaValueHead" + this.state.monthnih + patientId, value);
    } else {
      localStorage.setItem("localErythmaValueHead" + this.state.week + patientId, value);
    }
    this.setState({ erythema: value });
  }

  handleIndurationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localIndurationValueHead" + this.state.monthnih + patientId, value);
    } else {
      localStorage.setItem("localIndurationValueHead" + this.state.week + patientId, value);
    }
    this.setState({ induration: value });
  }

  handleExcoriationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localExcoriationValueHead" + this.state.monthnih + patientId, value);
    } else {
      localStorage.setItem("localExcoriationValueHead" + this.state.week + patientId, value);
    }
    this.setState({ excoriation: value });
  }
  handleScalingValueChange = (value) => {
    let patientId = this.props.match.params.id;
    localStorage.setItem("localScalingValueHead" + this.state.monthnih + patientId, value);
    this.setState({ scaling: value });
  }
  handleLichenificationValueChange = (value) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    if (studyId === "4") {
      localStorage.setItem("localLichenificationValueHead" + this.state.monthnih + patientId, value);
    } else {
      localStorage.setItem("localLichenificationValueHead" + this.state.week + patientId, value);
    }
    this.setState({ lichenification: value });
  }

  onChangeAreaScore = (e) => {
    this.setState({ areaScoreValue: e.target.value })
  }

  // rotateImage = (index, direction) => {
  //   console.log('index, direction',index, direction);
    
  //   this.setState((prevState) => {
  //     console.log('prevState',prevState);
      
  //     const newAngles = [...prevState.rotationAngles];
  //     console.log('newAngles',newAngles);
      
  //     let newRotationAngle = newAngles[index] + (direction === 'left' ? -90 : 90);

  //     // Normalize the angle to stay within the range [0, 360]
  //     if (newRotationAngle < 0) {
  //       newRotationAngle += 360;
  //     } else if (newRotationAngle >= 360) {
  //       newRotationAngle -= 360;
  //     }

  //     // Update the rotation angle in the state
  //     newAngles[index] = newRotationAngle;
  //     this.setState({ rotationAngleState: newRotationAngle })

  //     // Log the current rotation position for the image
  //     console.log(`Image at index ${index} is rotated to ${newRotationAngle} degrees`);

  //     return { rotationAngles: newAngles };
  //   });
  // };

  rotateImage = (index, direction) => {
    this.setState((prevState) => {
      console.log('prevState',prevState);
      
      const newAngles = [...prevState.rotationAngles];
      newAngles[index] += direction === 'left' ? -90 : 90;
      return { rotationAngles: newAngles };
    });
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const {
      flarePhotos,
      submitted,
      selectedCol,
      selectedMonth,
      selectedWeek,
      photoGallery,
      submitAreaScore,
      loading,
      selectedBodyPart,
      patientSelfEstimation,
      patientSelfEstimationJanssen,
      easiScores,
      selectedScaling,
      bodypartTypeByWeek,
      initialSlide,
      edemaValue,
      excoriationsValue,
      erythemaValue,
      ascoScopeValue,
      edema,
      erythema,
      ascoScope,
      excoriation,
      currentImageIndex,
      rotationAngles,
      selectedMonthNih,
      partTypeNih,
      monthnih,
      rotationAngleState
    } = this.state;
    const patientData = this.props.patientData;
    const { photoGalleryByWeek } = this.props;

    const patientDataHealth = this.props.patientData && this.props.patientData[0];



    let week = this.state.week;
    let weekNumber;

    switch (week) {
      case 1:
        weekNumber = 0;
        break;
      case 3:
        weekNumber = 2;
        break;
      case 5:
        weekNumber = 4;
        break;
      case 7:
        weekNumber = 6;
        break;
      case 9:
        weekNumber = 8;
        break;
      default:
        weekNumber = 0; // handle the case when week is not 1, 2, 3, or 4
        break;
    }

    let patientId = this.props.match.params.id;
    let selectedErythma = localStorage.getItem("localErythmaValueHead" + this.state.week + patientId);
    let selectedInduration = localStorage.getItem("localIndurationValueHead" + this.state.week + patientId);
    let selectedExcoriation = localStorage.getItem("localExcoriationValueHead" + this.state.week + patientId);
    let selectedLichenification = localStorage.getItem("localLichenificationValueHead" + this.state.week + patientId);

    let localErythmaValue = localStorage.getItem("localErythmaValueHead" + monthnih + patientId);
    let localIndurationValue = localStorage.getItem("localIndurationValueHead" + monthnih + patientId);
    let localExcoriationValue = localStorage.getItem("localExcoriationValueHead" + monthnih + patientId);
    let localLichenificationValue = localStorage.getItem("localLichenificationValueHead" + monthnih + patientId);
    let localScalingValue = localStorage.getItem("localScalingValueHead" + monthnih + patientId);

    var reviewStandards = Config.reviewStandard.url;
    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const studyId = localStorage.getItem("studyId");

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                this.state.data.title
              }
            />
            <Row gutter={16}>
              <Col xs={24} sm={12} md={6} xl={6}>
                <div className="white-box user-section">
                  <div className="user-profile">
                    <Link to={"/patient-progress/" + this.props.match.params.id} >
                      <Avatar size={84}>
                        {this.props.patientData &&
                          this.props.patientData[0].firstName
                            .toUpperCase()
                            .charAt(0) +
                          this.props.patientData[0].lastName
                            .toUpperCase()
                            .charAt(0)}
                      </Avatar>
                    </Link>
                    <div className="profile-details">
                      <Link to={"/patient-progress/" + this.props.match.params.id} >
                        <h3>
                          {this.props.patientData &&
                            this.props.patientData[0].firstName +
                            " " +
                            this.props.patientData[0].lastName}
                        </h3>
                      </Link>
                      {
                        studyId == 1 ?
                          <button className="btn" onClick={() => this.goToChat()}>
                            Chat Now
                          </button>
                          :
                          ""
                      }
                    </div>
                  </div>
                  <div className="user-details">
                    <List
                      style={{ marginTop: "40px" }}
                      itemLayout="horizontal"
                      dataSource={this.props.patientData}
                      renderItem={(patient) => (
                        <>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Study:
                            </Typography.Text>{" "}
                            {patient && patient.title}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Email:
                            </Typography.Text>{" "}
                            {patient.email}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Date of Birth:
                            </Typography.Text>{" "}
                            {/* {patient.dateOfBirth
                              ? moment(new Date(Number(patient.dateOfBirth))).format(
                                "DD-MM-YYYY"
                              )
                              : "N/A"} */}
                            {finalDate}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Age:
                            </Typography.Text>{" "}
                            {patient.age ? patient.age + " years" : "N/A"}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Phone Number:
                            </Typography.Text>{" "}
                            {"(" +
                              patient.phoneCode +
                              ") " +
                              patient.phoneNumber}
                          </List.Item>
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Member Status:
                            </Typography.Text>{" "}
                            {patient.userScreeningStatus.replace("_", " ")}
                          </List.Item>
                          {
                            studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Patient's TimeZone:
                                </Typography.Text>{" "}
                                {patient.notificationTimezone}
                              </List.Item>
                              : ""
                          }
                          {
                            studyId == 2 ?
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Notifications Time:
                                </Typography.Text>{" "}
                                {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                              </List.Item>
                              :
                              ""
                          }
                          <List.Item>
                            <Typography.Text style={{ color: "#006297" }}>
                              Registered Date:
                            </Typography.Text>{" "}
                            {patient.createdDate
                              ? moment(patient.createdDate)
                                .format(dateFormathh)
                              : "N/A"}
                          </List.Item>
                        </>
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={18} xl={18}>
                <ButtonGroup className="horizontal-button-tabs spaces flare-scores">
                  <Button
                    className={selectedBodyPart === "Head" ? "active" : ""}
                  >
                    Head
                  </Button>
                  <Button onClick={() => this.goToUpperExt()}>
                    {" "}
                    {"Upper Limbs"}
                  </Button>
                  <Button onClick={() => this.goToLowerExt()}>
                    {"Lower Limbs"}
                  </Button>
                  <Button onClick={() => this.goToTrunk()}>Trunk</Button>
                  <Button onClick={() => this.goToReviewTotal()}>
                    Review Total Scores
                  </Button>
                  <Button onClick={() => this.goToGlobalAssessment()}>
                    Global Assessment
                  </Button>
                </ButtonGroup>

                <Card
                  title={studyId === "4" && patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ? "Flare PASI Head" : "Flare EASI Head"}
                  extra={
                    <Button onClick={() => this.goToPatientProgress()}>
                      Back
                    </Button>
                  }
                  style={{ marginBottom: "8px", padding: "0 16px" }}
                  className="patient-dairy-section"
                >
                  {/* Activity Content Section Start */}
                  <div className="dairy-content-section">
                    <Spin spinning={loading}>
                      {/* <div className="slide-content"> */}
                      <div className="images-container">
                        <Carousel
                          arrows={true}
                          prevArrow={<LeftOutlined />}
                          nextArrow={<RightOutlined />}
                          className="images-slider"
                          slidesPerRow={3}
                          swipeToSlide={true}
                          touchThreshold={20}
                          focusOnSelect={true}
                          centerMode={true}
                          ref={(carousel) => (this.nav = carousel)}
                          draggable={true}
                          autoplay={false}
                          vertical
                          effect="fade"
                        >
                          {flarePhotos &&
                            flarePhotos
                              .filter((img) => img.category === "head") // Filter for category === "head"
                              .map((img, index) => (
                                <div style={{ height: '100%' }} key={img.id}>
                                  <img
                                    src={img.url}
                                    alt={img.desc}
                                    onClick={() => this.showModalOth(img, index)}
                                    loading="lazy"
                                  />
                                  <Typography.Text>{img.desc}</Typography.Text>
                                </div>
                              ))}
                        </Carousel>
                        <div>
                          <Modal
                            centered
                            width={450}
                            className="photo-zoom-popup"
                            // title={this.state.image.desc}
                            title={"Head Images"}
                            visible={this.state.isModalVisible}
                            onCancel={this.handleCancel}
                            footer={null}
                          >
                            <Carousel
                              arrows={true}
                              prevArrow={<LeftOutlined />}
                              nextArrow={<RightOutlined />}
                              className="modal-slider images-slider"
                              initialSlide={currentImageIndex}
                              key={currentImageIndex}
                            >
                              {flarePhotos &&
                                flarePhotos
                                  .filter((img) => img.category === "head") // Filter for category === "head"
                                  .map((img, index) => (
                                    <div key={index}>
                                      <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                        {({ zoomIn, zoomOut }) => (
                                          <>
                                            <div style={{ textAlign: 'center' }}>
                                              <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                              <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                              {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                              <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                            </div>
                                            <TransformComponent>
                                              <img
                                                width={400}
                                                src={img.url}
                                                alt={img.desc}
                                                loading="lazy"
                                                style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                              />
                                            </TransformComponent>
                                          </>
                                        )}
                                      </TransformWrapper>
                                      <Typography.Text>{img.desc}</Typography.Text>
                                    </div>
                                  ))}
                            </Carousel>
                          </Modal>
                        </div>
                      </div>
                      <Button
                        onClick={() => this.goToFlarePhotoGallery()}
                        type="primary"
                        className="btn-sq"
                        style={{ marginBottom: 30 }}
                      >
                        View all photos
                      </Button>
                      <div className="review-data-section">
                        {
                          patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ? <>
                            <Row gutter={16} className="data-header-row">
                              <Col xs={24} md={8}>
                                &nbsp;
                              </Col>
                              <Col xs={24} md={16}>
                                <div className="value-header">
                                  <div className="value-header-col">None</div>
                                  <div className="value-header-col">Slight</div>
                                  <div className="value-header-col">Moderate</div>
                                  <div className="value-header-col">Severe</div>
                                  <div className="value-header-col">Very Severe</div>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Erythema
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  // value={easiScores && easiScores.erythema ? easiScores.erythema : localErythmaValue}
                                  value={this.state.erythema}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() => {
                                      const value = "None";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 0,
                                        erythema: "None",
                                      });
                                    }}
                                  />

                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() => {
                                      const value = "Slight";
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 1,
                                        erythema: "Slight",
                                      });
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() => {
                                      const value = "Moderate"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 2,
                                        erythema: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() => {
                                      const value = "Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 3,
                                        erythema: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() => {
                                      const value = "Very_Severe"
                                      this.handleErythemaValueChange(value);
                                      this.setState({
                                        erythemaValue: 4,
                                        erythema: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Induration/Thickness
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.induration}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() => {
                                      const value = "None"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 0,
                                        induration: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() => {
                                      const value = "Slight"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 1,
                                        induration: "Slight",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() => {
                                      const value = "Moderate"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 2,
                                        induration: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() => {
                                      const value = "Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 3,
                                        induration: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() => {
                                      const value = "Very_Severe"
                                      this.handleIndurationValueChange(value)
                                      this.setState({
                                        indurationValue: 4,
                                        induration: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>
                            <Row gutter={16} className="data-content-row">
                              <Col xs={24} md={8}>
                                Scaling
                              </Col>
                              <Col xs={24} md={16}>
                                <Radio.Group
                                  value={this.state.scaling}
                                  className="review-value-selection"
                                >
                                  <Radio
                                    key="ER1"
                                    value={"None"}
                                    onChange={() => {
                                      const value = "None"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 0,
                                        scaling: "None",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER2"
                                    value={"Slight"}
                                    onChange={() => {
                                      const value = "Slight"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 1,
                                        scaling: "Slight",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER3"
                                    value={"Moderate"}
                                    onChange={() => {
                                      const value = "Moderate"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 2,
                                        scaling: "Moderate",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER4"
                                    value={"Severe"}
                                    onChange={() => {
                                      const value = "Severe"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 3,
                                        scaling: "Severe",
                                      })
                                    }}
                                  />
                                  <Radio
                                    key="ER5"
                                    value={"Very_Severe"}
                                    onChange={() => {
                                      const value = "Very_Severe"
                                      this.handleScalingValueChange(value)
                                      this.setState({
                                        scalingValue: 4,
                                        scaling: "Very_Severe",
                                      })
                                    }}
                                  />
                                </Radio.Group>
                              </Col>
                            </Row>  </> :
                            <>
                              <Row gutter={16} className="data-header-row">
                                <Col xs={24} md={8}>
                                  &nbsp;
                                </Col>
                                <Col xs={24} md={16}>
                                  <div className="value-header">
                                    <div className="value-header-col">None</div>
                                    <div className="value-header-col">Mild</div>
                                    <div className="value-header-col">Mild Moderate</div>
                                    <div className="value-header-col">Moderate</div>
                                    <div className="value-header-col">Moderate Severe</div>
                                    <div className="value-header-col">Severe</div>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={16} className="data-content-row">
                                <Col xs={24} md={8}>
                                  Erythema
                                </Col>
                                <Col xs={24} md={16}>
                                  <Radio.Group
                                    value={this.state.erythema}
                                    className="review-value-selection"
                                  >
                                    <Radio
                                      key="ER1"
                                      value={"None"}
                                      onChange={() => {
                                        const value = "None";
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 0,
                                          erythema: "None",
                                        });
                                      }}
                                    />

                                    <Radio
                                      key="ER2"
                                      value={"Mild"}
                                      onChange={() => {
                                        const value = "Mild";
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 1,
                                          erythema: "Mild",
                                        });
                                      }}
                                    />
                                    <Radio
                                      key="ER3"
                                      value={"Mild_Moderate"}
                                      onChange={() => {
                                        const value = "Mild_Moderate";
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 1.5,
                                          erythema: "Mild_Moderate",
                                        });
                                      }}
                                    />
                                    <Radio
                                      key="ER4"
                                      value={"Moderate"}
                                      onChange={() => {
                                        const value = "Moderate"
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 2,
                                          erythema: "Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER5"
                                      value={"Moderate_Severe"}
                                      onChange={() => {
                                        const value = "Moderate_Severe"
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 2.5,
                                          erythema: "Moderate_Severe",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER6"
                                      value={"Severe"}
                                      onChange={() => {
                                        const value = "Severe"
                                        this.handleErythemaValueChange(value);
                                        this.setState({
                                          erythemaValue: 3,
                                          erythema: "Severe",
                                        })
                                      }}
                                    />
                                  </Radio.Group>
                                </Col>
                              </Row>
                              <Row gutter={16} className="data-content-row">
                                <Col xs={24} md={8}>
                                  Induration/Papulation
                                </Col>
                                <Col xs={24} md={16}>
                                  <Radio.Group
                                    value={this.state.induration}
                                    className="review-value-selection"
                                  >
                                    <Radio
                                      key="ER1"
                                      value={"None"}
                                      onChange={() => {
                                        const value = "None"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 0,
                                          induration: "None",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER2"
                                      value={"Mild"}
                                      onChange={() => {
                                        const value = "Mild"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 1,
                                          induration: "Mild",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER3"
                                      value={"Mild_Moderate"}
                                      onChange={() => {
                                        const value = "Mild_Moderate"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 1.5,
                                          induration: "Mild_Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER4"
                                      value={"Moderate"}
                                      onChange={() => {
                                        const value = "Moderate"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 2,
                                          induration: "Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER5"
                                      value={"Moderate_Severe"}
                                      onChange={() => {
                                        const value = "Moderate_Severe"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 2.5,
                                          induration: "Moderate_Severe",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER6"
                                      value={"Severe"}
                                      onChange={() => {
                                        const value = "Severe"
                                        this.handleIndurationValueChange(value)
                                        this.setState({
                                          indurationValue: 3,
                                          induration: "Severe",
                                        })
                                      }}
                                    />
                                  </Radio.Group>
                                </Col>
                              </Row>
                              <Row gutter={16} className="data-content-row">
                                <Col xs={24} md={8}>
                                  Excoriation
                                </Col>
                                <Col xs={24} md={16}>
                                  <Radio.Group
                                    value={this.state.excoriation}
                                    className="review-value-selection"
                                  >
                                    <Radio
                                      key="ER1"
                                      value={"None"}
                                      onChange={() => {
                                        const value = "None"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 0,
                                          excoriation: "None",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER2"
                                      value={"Mild"}
                                      onChange={() => {
                                        const value = "Mild"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 1,
                                          excoriation: "Mild",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER3"
                                      value={"Mild_Moderate"}
                                      onChange={() => {
                                        const value = "Mild_Moderate"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 1.5,
                                          excoriation: "Mild_Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER4"
                                      value={"Moderate"}
                                      onChange={() => {
                                        const value = "Moderate"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 2,
                                          excoriation: "Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER5"
                                      value={"Moderate_Severe"}
                                      onChange={() => {
                                        const value = "Moderate_Severe"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 2.5,
                                          excoriation: "Moderate_Severe",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER6"
                                      value={"Severe"}
                                      onChange={() => {
                                        const value = "Severe"
                                        this.handleExcoriationValueChange(value)
                                        this.setState({
                                          excoriationsValue: 3,
                                          excoriation: "Severe",
                                        })
                                      }}
                                    />
                                  </Radio.Group>
                                </Col>
                              </Row>
                              <Row gutter={16} className="data-content-row">
                                <Col xs={24} md={8}>
                                  Lichenification
                                </Col>
                                <Col xs={24} md={16}>
                                  <Radio.Group
                                    value={this.state.lichenification}
                                    className="review-value-selection"
                                  >
                                    <Radio
                                      key="ER1"
                                      value={"None"}
                                      onChange={() => {
                                        const value = "None"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 0,
                                          lichenification: "None",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER2"
                                      value={"Mild"}
                                      onChange={() => {
                                        const value = "Mild"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 1,
                                          lichenification: "Mild",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER3"
                                      value={"Mild_Moderate"}
                                      onChange={() => {
                                        const value = "Mild_Moderate"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 1.5,
                                          lichenification: "Mild_Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER4"
                                      value={"Moderate"}
                                      onChange={() => {
                                        const value = "Moderate"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 2,
                                          lichenification: "Moderate",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER5"
                                      value={"Moderate_Severe"}
                                      onChange={() => {
                                        const value = "Moderate_Severe"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 2.5,
                                          lichenification: "Moderate_Severe",
                                        })
                                      }}
                                    />
                                    <Radio
                                      key="ER6"
                                      value={"Severe"}
                                      onChange={() => {
                                        const value = "Severe"
                                        this.handleLichenificationValueChange(value)
                                        this.setState({
                                          lichenificationValue: 3,
                                          lichenification: "Severe",
                                        })
                                      }}
                                    />
                                  </Radio.Group>
                                </Col>
                              </Row>
                            </>
                        }
                      </div>
                      <div className="area-score-section">
                        <h5>Area Score</h5>
                        <Radio.Group
                          value={this.state.areaScoreValue ? this.state.areaScoreValue.toString() :
                            easiScores && easiScores.areaScore ? easiScores.areaScore.toString() :
                              (easiScores && easiScores.areaScore === 0 || easiScores && easiScores.areaScore === "0") ? "0" : ""}
                          onChange={this.onChangeAreaScore}
                          className="review-value-selection"
                        >
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 0,
                              })
                            }
                            key="no Eczema"
                            value={"0"}
                          >
                            {"0%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 1,
                              })
                            }
                            key="no Eczema1"
                            value={"1"}
                          >
                            {"1-9%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 2,
                              })
                            }
                            key="no Eczema2"
                            value={"2"}
                          >
                            {"10-29%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 3,
                              })
                            }
                            key="no Eczema3"
                            value={"3"}
                          >
                            {"30-49%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 4,
                              })
                            }
                            key="no Eczema4"
                            value={"4"}
                          >
                            {"50-69%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 5,
                              })
                            }
                            key="no Eczema5"
                            value={"5"}
                          >
                            {"70-89%"}
                          </Radio>
                          <Radio
                            onChange={() =>
                              this.setState({
                                areaScoreValue: 6,
                              })
                            }
                            key="no Eczema6"
                            value={"6"}
                          >
                            {"90-100%"}
                          </Radio>
                        </Radio.Group>
                      </div>
                      {easiScores && typeof easiScores.totalScore !== 'undefined' ? (
                        <Form
                          key={easiScores.totalScore}
                          name="easi-head-form"
                          className="easi-head-form"
                          layout="inline"
                          onFinish={studyId == "4" ? this.savePasiScore : this.saveEASIHeadScores}
                          initialValues={easiScores && easiScores.totalScore}
                        >
                          <Form.Item
                            name="headTotal"
                            label={studyId === "4" && patientData && patientData[0] && patientData[0].healthCondition === "PSORIASIS" ? "PASI head total" : "EASI head total"}>
                            <Input
                              defaultValue={easiScores && easiScores.totalScore}
                              // value={easiScores && easiScores.totalScore} 
                              disabled
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-sq"
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      ) : (
                        <Form
                          name="easi-head-form"
                          className="easi-head-form"
                          layout="inline"
                          onFinish={studyId == "4" ? this.savePasiScore : this.saveEASIHeadScores}
                        >
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-sq"
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      )}
                    </Spin>
                  </div>

                  {/* /Activity Content Section End */}
                </Card>

                <ul className="link-list-box" style={{ display: "block" }}>
                  {
                    studyId == 1 ?
                      <li>
                        <div onClick={() => this.goToChat()} class="link-box">
                          <ChatIcon />
                          <h4>Chat</h4>
                        </div>
                      </li>
                      :
                      ""
                  }
                  <li>
                    <div
                      onClick={() => this.goToEASiScoring()}
                      className={
                        selectedCol === "survey"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <ServeyIcon />
                      <h4>Surveys &amp; Scores</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToDiary("diary")}
                      className={
                        selectedCol === "diary"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <DairyIcon />
                      <h4>Diary</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToPhotoGallery("gallery")}
                      className={
                        selectedCol === "gallery"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <PhotogalleryIcon />
                      <h4>Photo Gallery </h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToAdverseEvents("adverse")}
                      className={
                        selectedCol === "adverse"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <AdverseEventIcon />
                      <h4>Adverse Events</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToActivityData("activity")}
                      class="link-box"
                    >
                      <DataIcon />
                      <h4>Activity Data</h4>
                    </div>
                  </li>
                  {
                    studyId == 1 ?
                      <li>
                        <div
                          onClick={() => this.goToStudyDrugLog("log")}
                          class="link-box"
                        >
                          <StudyDrugLogIcon />
                          <h4>Study Drug Log</h4>
                        </div>
                      </li>
                      :
                      ""
                  }
                  {
                    studyId == 1 ?
                      <li>
                        <div onClick={() => this.goToLab("lab")} class="link-box">
                          <LabIcon />
                          <h4>Lab</h4>
                        </div>
                      </li>
                      :
                      ""
                  }
                  {
                    studyId == 4 && (patientDataHealth && patientDataHealth.healthCondition !== "HEALTHY_CONTROL") ?
                      <li>
                        <div
                          onClick={() => this.goToFlares()}
                          class="link-box extra"
                        >
                          <AuditOutlined style={{ fontSize: "35px" }} />
                          <h4>Flares</h4>
                        </div>
                      </li>
                      : ""
                  }
                  {
                    studyId === "4" ?
                      <li>
                        <div
                          onClick={() => this.goToNewMed()}
                          class="link-box extra"
                        >
                          <MedicationIcon />
                          <h4>Medication</h4>
                        </div>
                      </li>
                      : ""
                  }
                </ul>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    patientSelfEstimation: state.patient.patientSelfEstimation,
    patientSelfEstimationJanssen: state.patient.patientSelfEstimationJanssen,
    easiScores: state.patient.easiScores,
    flareEasiScores: state.easiScore.flareEasiScores,
    flarePasEasScores: state.easiScore.flarePasEasScores,
    patientGalleryByWeek: state.patient.patientGalleryByWeek,
    photoGallery: state.patient.photoGallery,
    flareImages: state.diary.flareImages,
    janssenImagesScore: state.patient.janssenImagesScore,
    easiHeadScores: state.easiScore.easiHeadScores,
    updatePatientSelfEstimation: state.patient.updatePatientSelfEstimation,
    weekByParts: state.patient.weekByParts,
    pasiScores: state.easiScore.pasiScoreNih
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...EasiScoreActions, ...PatientDiaryActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FlareHead
  )
);
