import API from "../api/patient-diary-api";
import API2 from "../api/patients-api";
import { Types } from "../constants/diary-types";

export function getVoiceDiaries(patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getVoiceDiaries(patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_VOICE_DIARIES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.VOICE_DIARIES_NOT_FOUND,
          payload: "voice diaries not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getFlareVoiceDiaries(patientId, uploadDate) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getFlareVoiceDiaries(patientId, uploadDate);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_VOICE_DIARIES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.VOICE_DIARIES_NOT_FOUND,
          payload: "voice diaries not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPhotoDiaryImage(imagePath) {
  return async function (dispatch, _getState) {
    try {
      var imageData = [];
      let resp = await API.getPhotoDiaryImage(imagePath);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({url: data });
        dispatch({
          type: Types.GET_IMAGE_URL,
          payload: imageData
        });
      } else {
        dispatch({
          type: Types.IMAGE_URL_NOT_FOUND,
          payload: imageData
        });
      }
      return imageData;
    } catch (e) {
      return { error: true };
    }
  };
}


// export function getAllFlareImages(userId, uploadDate) {
//   return async function (dispatch, getState) {
//     try {
//       let response = await API.getAllFlareImages(userId, uploadDate);
//       let studyImages = [];
//       if (response && response.flareImages) {
//         studyImages = response.flareImages
//         var imageData = [];
//         for (let i = 0; i < studyImages.length; i++) {
//           const currentImage = studyImages[i];
//           if (Array.isArray(currentImage.filename)) {
//             for (let j = 0; j < currentImage.filename.length; j++) {
//               const resp = await API2.getJanssenPatientSurveyImage(`${currentImage.imageUrl}/${currentImage.filename[j]}`);
//               if (resp) {
//                 imageData.push({ desc: currentImage.name, url: resp });
//               }
//             }
//           } else {
//             const resp = await API2.getJanssenPatientSurveyImage(currentImage.imageUrl);
//             if (resp) {
//               imageData.push({ desc: 'Affected area', url: resp });
//             }
//           }
//         }
//         await dispatch({
//           type: Types.FLARE_PHOTO,
//           payload: imageData,
//         });   
//         return imageData;
//       } 
//     } catch (e) {
//       console.error('Error fetching image data:', e);
//       dispatch({
//         type: Types.FLARE_PHOTO_NOT_FOUND,
//         payload: { error: true, message: e.message },
//       });
//       return { e: true };
//     }
//   };
// }


// const getPhotoGalleryDataJanssen = async (studyImages, dispatch) => {
//   const imageData = [];
//   try {
    
//   } catch (error) {
    
//   }
// };

// const getPhotoGalleryDataJanssen = async (studyImages, imageData, dispatch) => {
//   var i;
//   var j;
//   for (i = 0; i < studyImages.length; i++) {
//     if (typeof (studyImages[i].filename) !== 'string') {
//       for (j = 0; j < studyImages[i].filename.length; j++) {
//         let resp = await API2.getPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
//         if (resp) {
//           let data = URL.createObjectURL(resp);
//           imageData.push({ desc: studyImages[i].name, url: data });
//         }
//       }
//     } else {  //to be used only if list contains affectedAreaImages within allImages 
//       let resp = await API2.getPatientSurveyImage(studyImages[i].imageUrl);
//       if (resp) {
//         let data = URL.createObjectURL(resp);
//         imageData.push({ desc: "Affected area", url: data });
//       }
//     }
//   }
//   dispatch({
//     type: Types.FLARE_PHOTO,
//     payload: imageData,

//   });
//   return imageData;

// }

export function getAllFlareImages(userId, uploadDate) {
  return async function (dispatch) {
    try {
      // Fetch response from API
      const response = await API.getAllFlareImages(userId, uploadDate);
      console.log('response', response);

      if (response && response.flareImages) {
        // Define the categories and their respective keys in the response
        const imageCategories = {
          fullBodyImages: "fullBody",
          upperBodyImages: "upperBody",
          lowerBodyImages: "lowerBody",
          headAndNeckImages: "head",
          standardizedCloseUpImages: "closeUp",
        };

        let allImages = [];

        // Aggregate all images from different categories
        for (const [key, category] of Object.entries(imageCategories)) {
          if (response.flareImages[key]) {
            const imagesWithCategory = response.flareImages[key].map((image) => ({
              ...image,
              category,
            }));
            allImages.push(...imagesWithCategory);
          }
        }

        const imageData = [];

        // Fetch and process images for each entry
        for (let image of allImages) {
          if (Array.isArray(image.filename)) {
            for (let file of image.filename) {
              const imageUrl = `${image.imageUrl}/${file}`;
              const resp = await API2.getJanssenPatientSurveyImage(imageUrl);

              if (resp) {
                imageData.push({
                  desc: image.name || 'Affected area',
                  url: resp,
                  fileDescription: image && image.fileDescription[0] || '',
                  category: image.category,
                });
              }
            }
          } else {
            const resp = await API2.getJanssenPatientSurveyImage(image.imageUrl);

            if (resp) {
              imageData.push({
                desc: image.name || 'Affected area',
                url: resp,
                fileDescription: image && image.fileDescription[0] || '',
                category: image.category,
              });
            }
          }
        }

        // Dispatch the processed data
        await dispatch({
          type: Types.FLARE_PHOTO,
          payload: imageData,
        });

        return imageData;
      }
    } catch (e) {
      console.error('Error fetching image data:', e);

      // Dispatch error in case of failure
      dispatch({
        type: Types.FLARE_PHOTO_NOT_FOUND,
        payload: { error: true, message: e.message },
      });

      return { error: true, message: e.message };
    }
  };
}



export function getTextDiaries(patientId, ) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getTextDiaries(patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_TEXT_DIARIES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.TEXT_DIARIES_NOT_FOUND,
          payload: "text diaries not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPhotoDiaries(patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getPhotoDiaries(patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_PHOTO_DIARIES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.PHOTO_DIARIES_NOT_FOUND,
          payload: "Photo diaries not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function addDoctorNotesOnVoiceDiary(data) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.addDoctorNotesOnVoiceDiary(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function addDoctorNotesOnTextDiary(data) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.addDoctorNotesOnTextDiary(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function addDoctorNotesOnPhotoDiary(data) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.addDoctorNotesOnPhotoDiary(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDoctorCommentsOnVoiceDiary(voiceId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDoctorCommentsOnVoiceDiary(voiceId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_VOICE_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_VOICE_NOTES_NOT_FOUND,
          payload: "Doctor voice notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDoctorCommentsOnTextDiary(textId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDoctorCommentsOnTextDiary(textId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_TEXT_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_TEXT_NOTES_NOT_FOUND,
          payload: "Doctor text notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDoctorCommentsOnPhotoDiary(textId) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getDoctorCommentsOnPhotoDiary(textId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_PHOTO_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_PHOTO_NOTES_NOT_FOUND,
          payload: "Doctor photo notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


