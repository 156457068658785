import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions";
import * as EasiScoreActions from "../../redux/actions/easi-scores-actions";
import "react-h5-audio-player/lib/styles.css";

import "react-h5-audio-player/lib/styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  AuditOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Radio,
  Carousel,
  Modal,
  Form,
  Input,
  PageHeader,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { LabSvg } from "../shared/svg/labicon";
import { MedicationIconSvg } from "../shared/svg/medication";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);
const ButtonGroup = Button.Group;


class FlareGlobalAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      submitted: false,
      patientVoiceDiary: [],
      selectedBodyPart: "globalAss",
      selectItemId: "",
      selectedCol: "",
      selectedMonth: 1,
      selectedMonthNih: 1,
      selectedWeek: 1,
      globalAssessment: {},
      month: 1,
      week: 1,
      monthNih: 1,
      range: "",
      flarePhotos: [],
      rotationAngles: Array(this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length).fill(0),
      currentImageIndex: 0,
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          //loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    await this.props.getPatientByIdNih(patientId);
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      //this.setState({ loading: false })
    }
    let date = this.props.match.params.date;
    let globalAssesment = await this.props.getFlareGlobalAssessment(date, patientId);
    

    let photoData = await this.props.getAllFlareImages(patientId, date);
    this.setState({flarePhotos: photoData, loading: false,});
    
       
    if (globalAssesment && globalAssesment.data) {
      this.setState({
        globalAssessment: globalAssesment.data,
        loading: false,
      });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.globalAssessment !== nextProps.globalAssessment) {
      this.setState({ globalAssessment: nextProps.globalAssessment });
      this.setState({ loading: false });
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };

  goToFlarePhotoGallery = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-photographs/" + patientId + "/" + taskDate);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  };

  goToHeadEASiScoring = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-head/" + patientId +"/"+ taskDate);
  };

  goToLowerExt = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-lowerExt/" + patientId +"/"+ taskDate);
  };
  goToUpperExt = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-upperExt/" + patientId +"/"+ taskDate);
  };

  goToTrunk = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-trunk/" + patientId +"/"+ taskDate);
  };

  goToReviewTotal = () => {
    var patientId = this.props.match.params.id;
    let taskDate = this.props.match.params.date;
    this.props.history.push("/flare-total/" + patientId +"/"+ taskDate);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };


  updateRange = async (range) => {
    this.setState({ range: range });
  };

  addGlobalAssessment = async () => {
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let date = this.props.match.params.date;
    this.setState({ submitted: true });
    let data = {
        patientId: patientId,
        date: date,
        rangeMaxVal: this.state.range,
        studyId: studyId
    }
    if (this.state.range === "") {
      notifyUser("Please select a score", "error");
      this.setState({ submitted: false });
    } else {
      await this.props.saveFlareGlobalAssessment(data);
      notifyUser("Patient's global assessment updated successfully", "success");
      this.setState({ submitted: false });
    }
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  showModalOth = (img, index) => {
    this.setState({
      isModalVisible: true,
      currentImageIndex: index,
      image: img,
    });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, rotationAngleState: "", rotationAngles: Array(this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length).fill(0) });
  };

  render() {
    const {
      submitted,
      selectedCol,
      selectedMonth,
      selectedWeek,
      loading,
      globalAssessment,
      selectedBodyPart,
      selectedMonthNih,
      flarePhotos,
      currentImageIndex,
      rotationAngles
    } = this.state;
    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }

    const patientDataHealth = this.props.patientData && this.props.patientData[0];

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data.title
              }
            ></PageHeader>
            <Spin spinning={loading}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId == 1 ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button>
                            :
                            ""
                          }
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient && patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>

                    <ButtonGroup className="horizontal-button-tabs spaces flare-scores">
                      <Button onClick={() => this.goToHeadEASiScoring()}>
                        Head
                      </Button>
                      <Button onClick={() => this.goToUpperExt()}>
                        {studyId == 4 ? "Upper Limbs" : "Upper Extremity"}
                      </Button>
                      <Button onClick={() => this.goToLowerExt()}>
                        {studyId == 4 ? "Lower Limbs" : "Lower Extremity"}
                      </Button>
                      <Button onClick={() => this.goToTrunk()}>Trunk</Button>
                      <Button onClick={() => this.goToReviewTotal()}>
                        Review Total Scores
                      </Button>
                      <Button
                        className={
                          selectedBodyPart === "globalAss" ? "active" : ""
                        }
                      >
                        Global Assessment
                      </Button>
                    </ButtonGroup>
                    <Card
                      title={"Flare Global Assessment"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={24} md={24} xl={24}>
                          {/* Activity Content Section Start */}
                          <div className="dairy-content-section">
                            <Spin spinning={loading}>
                              <div className="images-container">
                                <Carousel
                                  arrows={true}
                                  prevArrow={<LeftOutlined />}
                                  nextArrow={<RightOutlined />}
                                  className="images-slider"
                                  slidesPerRow={3}
                                  swipeToSlide={true}
                                  touchThreshold={20}
                                  focusOnSelect={true}
                                  centerMode={true}
                                  ref={(carousel) => (this.nav = carousel)}
                                  draggable={true}
                                  autoplay={false}
                                  vertical
                                  effect="fade"
                                >
                                  {flarePhotos &&
                                    flarePhotos
                                      .filter((img) => img.category === "closeUp") // Filter for category === "head"
                                      .map((img, index) => (
                                        <div style={{ height: '100%' }} key={img.id}>
                                          <img
                                            src={img.url}
                                            alt={img.desc}
                                            onClick={() => this.showModalOth(img, index)}
                                            loading="lazy"
                                          />
                                          <Typography.Text>{img.desc}</Typography.Text>
                                        </div>
                                      ))}
                                </Carousel>
                                <div>
                                  <Modal
                                    centered
                                    width={450}
                                    className="photo-zoom-popup"
                                    // title={this.state.image.desc}
                                    title={"Lower Limbs Images"}
                                    visible={this.state.isModalVisible}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                  >
                                    <Carousel
                                      arrows={true}
                                      prevArrow={<LeftOutlined />}
                                      nextArrow={<RightOutlined />}
                                      className="modal-slider images-slider"
                                      initialSlide={currentImageIndex}
                                      key={currentImageIndex}
                                    >
                                      {flarePhotos &&
                                        flarePhotos
                                          .filter((img) => img.category === "closeUp") // Filter for category === "head"
                                          .map((img, index) => (
                                            <div key={index}>
                                              <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
                                                {({ zoomIn, zoomOut }) => (
                                                  <>
                                                    <div style={{ textAlign: 'center' }}>
                                                      <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                      <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                      {/* <RotateLeftOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'left')} />
                                                      <RotateRightOutlined style={{ marginRight: 8 }} size={20} onClick={() => this.rotateImage(index, 'right')} /> */}
                                                    </div>
                                                    <TransformComponent>
                                                      <img
                                                        width={400}
                                                        src={img.url}
                                                        alt={img.desc}
                                                        loading="lazy"
                                                        style={{ transform: `rotate(${rotationAngles[index]}deg)` }}
                                                      />
                                                    </TransformComponent>
                                                  </>
                                                )}
                                              </TransformWrapper>
                                              <Typography.Text>{img.desc}</Typography.Text>
                                            </div>
                                          ))}
                                    </Carousel>
                                  </Modal>
                                </div>
                              </div>
                              <div className="review-all-photod" style={{textAlign: "center"}}>
                                  <Button
                                    onClick={() => this.goToFlarePhotoGallery()}
                                    type="primary"
                                    className="btn-sq"
                                    style={{ marginBottom: 30 }}
                                  >
                                    Review All Photos
                                  </Button>
                              </div>
                              <div className="patient-data-section">
                                <div className="play-icon-container"></div>
                                {console.log('globalAssessment',globalAssessment)
                                }
                                <Spin spinning={submitted}>
                                  {globalAssessment ? (
                                    <div className="review-data-section">
                                      <Row gutter={16} className="data-header-row">
                                        <Col xs={24} md={8} xl={6}>
                                          Global Assessment
                                        </Col>
                                        <Col xs={24} md={16} xl={18}>
                                          <div className="value-header">
                                            <div className="value-header-col">
                                              Clear (0)
                                            </div>
                                            <div className="value-header-col">
                                              Almost Clear (1)
                                            </div>
                                            <div className="value-header-col">
                                              Mild (2)
                                            </div>
                                            <div className="value-header-col">
                                              Moderate (3)
                                            </div>
                                            <div className="value-header-col">
                                              Severe (4)
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row gutter={16} className="data-content-row">
                                        <Col xs={24} md={8} xl={6}>
                                          Please select a score
                                        </Col>
                                        <Col xs={24} md={16} xl={18}>
                                          <Radio.Group
                                            defaultValue={
                                              globalAssessment &&
                                                globalAssessment.rangeMaxVal
                                                ? globalAssessment.rangeMaxVal
                                                : "5"
                                            }
                                            className="review-value-selection"
                                          >
                                            <Radio
                                              onClick={() => this.updateRange("0")}
                                              key="ER1"
                                              value={"0"}
                                            />
                                            <Radio
                                              onClick={() => this.updateRange("1")}
                                              key="ER2"
                                              value={"1"}
                                            />
                                            <Radio
                                              onClick={() => this.updateRange("2")}
                                              key="ER3"
                                              value={"2"}
                                            />
                                            <Radio
                                              onClick={() => this.updateRange("3")}
                                              key="ER4"
                                              value={"3"}
                                            />
                                            <Radio
                                              onClick={() => this.updateRange("4")}
                                              key="ER5"
                                              value={"4"}
                                            />
                                          </Radio.Group>
                                        </Col>
                                      </Row>

                                      <Row gutter={16} className="data-content-row">
                                        <Col xs={24} md={8}>
                                          <Button
                                            onClick={() =>
                                              this.addGlobalAssessment()
                                            }
                                            type="primary"
                                            className="btn-sq"
                                          >
                                            Submit
                                          </Button>
                                        </Col>
                                        {/* <Col xs={24} md={16}>
                                          {" "}
                                          <Button
                                            onClick={() => this.goToFlarePhotoGallery()}
                                            type="primary"
                                            className="btn-sq"
                                            style={{ marginBottom: 30 }}
                                          >
                                            Review All Photos
                                          </Button>
                                        </Col> */}
                                      </Row>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Spin>
                              </div>
                            </Spin>
                          </div>
                          {/* /Activity Content Section End */}
                        </Col>
                      </Row>
                    </Card>

                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == 1 ?
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                          onClick={() => this.goToAdverseEvents("adverse")}
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToLab("lab")}
                            class="link-box"
                          >
                            <LabIcon />
                            <h4>Lab</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      {
                        studyId == 4 && (patientDataHealth && patientDataHealth.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box extra"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                      {
                        studyId === "4" ?
                        <li>
                          <div
                            onClick={() => this.goToNewMed()}
                            class="link-box extra"
                          >
                            <MedicationIcon/>
                            <h4>Medication</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    globalAssessment: state.easiScore.globalAssessment,
    weekByParts: state.patient.weekByParts
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...EasiScoreActions, ...PatientDiaryActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FlareGlobalAssessment
  )
);
