import API from "../api/easi-scores-api";
import { Types } from "../constants/score-types";

export function addEASIHeadScores(data) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.addEASIHeadScores(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.SAVE_EASI_HEAD_SCORE,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function savePasiScore(data) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.savePasiScore(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.SAVE_EASI_HEAD_SCORE,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function saveFlarePasiScore(data) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.saveFlarePasiScore(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.FLARE_EASI_SCORE,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getEasiTotal(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getEasiTotal(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EASI_TOTAL,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getScoresNih(userId,month,partType) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getScoresNih(userId,month,partType);
      if (resp && resp.data) {
        dispatch({
          type: Types.PASI_SCORE_NIH,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { e, error: true };
    }
  };
}

export function getPartsTotalScore(userId,date,partType) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getPartsTotalScore(userId,date,partType);
      console.log('resp',resp);
      
      if (resp && resp.data) {
        dispatch({
          type: Types.FLARE_EAS_PASI_SCORES,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { e, error: true };
    }
  };
}

export function getEasiScoreForJanssen(week,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getEasiScoreForJanssen(week,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EASI_TOTAL,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getEasiScoreForNih(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getEasiScoreForNih(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EASI_TOTAL,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getFlareEasiScores(date,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getFlareEasiScores(date,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EASI_TOTAL,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getGlobalAssessment(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getGlobalAssessment(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getGlobalAssessmentForJanssen(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getGlobalAssessmentForJanssen(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getGlobalAssessmentForNih(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getGlobalAssessmentForNih(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getFlareGlobalAssessment(date,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getFlareGlobalAssessment(date,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function saveGlobalAssessment(month,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.saveGlobalAssessment(month,patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.SAVE_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function saveFlareGlobalAssessment(data) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.saveFlareGlobalAssessment(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.SAVE_GLOBAL_ASSESSMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

