import { API } from "../../config";
import AxiosAuth2 from "../../services/axios-service-auth";
import { getToken } from "../../services/user-service";


const getPatientsList = (studyId) => {
  return AxiosAuth2.get("/api/patient/getAllPatients/"+studyId, undefined, undefined);
};

const approvePatient = (data) => {
  return AxiosAuth2.post("/api/study/updateStudyStatus/"+data.userId+"?status="+data.status, undefined, undefined);
};

const addPatientParade = (data) => {
  return AxiosAuth2.post("/api/auth/parade/registerUser/",data, undefined);
};

const getApprovedPatientsList = (studyId) => {
  return AxiosAuth2.get("/api/patient/getApprovedPatients/"+studyId, undefined, undefined);
};

const getApprovedPatientsListJanssen = () => {
  return AxiosAuth2.get("/api/janssen/patient/getApprovedPatients/", undefined, undefined);
};

const getApprovedPatientsListParade = () => {
  return AxiosAuth2.get("/api/ohsu/patient/getApprovedPatients/", undefined, undefined);
};

const getApprovedPatientsListNih = () => {
  return AxiosAuth2.get("/api/nih/patient/getApprovedPatients/", undefined, undefined);
};

const getDisapprovedPatientsList = (studyId) => {
  return AxiosAuth2.get("/api/patient/getDisapprovedPatients/"+studyId, undefined, undefined);
};

const getDisqualifiedPatientsList = (studyId) => {
  return AxiosAuth2.get("/api/patient/getDisqualifiedPatients/"+studyId, undefined, undefined);
};

const getPatientById = (data) => {
  return AxiosAuth2.get("/api/admin/getUser/"+data.userId, undefined, undefined);
};

const getPatientByIdJanssen = (data) => {
  return AxiosAuth2.get("/api/admin/getUserForJanssen/"+data.userId, undefined, undefined);
};

const getPatientByIdParade = (data) => {
  return AxiosAuth2.get("/api/admin/getUserForParade/"+data.userId, undefined, undefined);
};

const getPatientByIdNih = (data) => {
  return AxiosAuth2.get("/api/admin/getUserForNih/"+data.userId, undefined, undefined);
};

const getAuditLogs = (userId) => {
  return AxiosAuth2.get("/api/admin/getActivityLogs/"+userId, undefined, undefined);
};


const getQuesAnsList = (data) => {
  return AxiosAuth2.post("/api/study/reviewStudy", data, undefined);
};

const getPhotoGalleryByWeek = (data) => {
  return AxiosAuth2.post("/api/surveyImages/getAllImagesAndScore", data, undefined);
};

const getJansennPhotoGalleryByWeek = (data) => {
  return AxiosAuth2.post("/api/janssen/surveyImages/getAllImagesAndScore", data, undefined);
};
const getJansennPhotoGalleryByWeekNew = (data) => {
  return AxiosAuth2.post("/api/janssen/surveyImages/getAllImagesForPhysicianPortal", data, undefined);
};

const getNihPhotoGallery = (data) => {
  return AxiosAuth2.post("/api/nih/surveyImage/getAllNihImagesForPhysicianPortal", data, undefined);
};

const getAllOhsuImagesForPhysicianPortal = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/getAllOhsuImagesForPhysicianPortal", data, undefined);
};

const getAllImagesAndScoreForPhysicianPortal = (data) => {
  return AxiosAuth2.post("/api/janssen/surveyImages/getAllImagesAndScoreForPhysicianPortal", data, undefined);
};

const getPatientSelfEstimation = (month,patientId) => {
  return AxiosAuth2.get("/api/patientSelfEstimation/getSelfEstimationByPatientIdAndMonth?month="+month+"&patientId="+patientId, undefined, undefined);
};

const getPatientSelfEstimationJanssen = (week,patientId) => {
  return AxiosAuth2.get("/api/patientSelfEstimation/getSelfEstimationForJanssenStudyByPatientIdAndWeek?week="+week+"&patientId="+patientId, undefined, undefined);
};

const updateSelfEstimationScores = (data) => {
  return AxiosAuth2.post("/api/patientSelfEstimation/savePatientSelfEstimation", data, undefined);
};

const getPatientStudyImage = (data) => {
  return AxiosAuth2.get("/api/study/getImage?imagePath="+data, undefined, undefined, false, true);
};

const getPatientSurveyImage = (data) => {
  return AxiosAuth2.get("/api/surveyImages/getImage?imagePath="+data, undefined, undefined, false, true);
};

const getJanssenPatientSurveyImage = (data) => {
  return API+"api/janssen/surveyImages/getImage?imagePath="+data+"&x-auth-token="+getToken();
};

const getTasksList = (studyId) => {
  return AxiosAuth2.get("/api/patient/overdue-task-list/"+studyId, undefined, undefined);
};

const getInActivePatientsList = (studyId) => {
  return AxiosAuth2.get("/api/admin/getInactivePatients/"+studyId, undefined, undefined);
};

const getInactivePatientsForOhsu = (studyId) => {
  return AxiosAuth2.get("/api/admin/getInactivePatientsForOhsu/"+studyId, undefined, undefined);
};

const sendPendingTasks = (studyId, patientId) => {
  return AxiosAuth2.get("/api/patient/sendTasks/"+studyId+"/"+patientId, undefined, undefined);
};

const sendPendingTasksJanssen = (studyId, patientId) => {
  return AxiosAuth2.get("/api/janssen/patient/sendTasks/"+studyId+"/"+patientId, undefined, undefined);
};

const deleteDuplicateTasks = (studyId, patientId) => {
  return AxiosAuth2.get("/api/patient/deleteDuplicateTasks/"+studyId+"/"+patientId, undefined, undefined);
};

const deleteDuplicateTasksJanssen = (studyId, patientId) => {
  return AxiosAuth2.get("/api/janssen/patient/deleteDuplicateTasks/"+studyId+"/"+patientId, undefined, undefined);
};

const getAffectedAreaImage = (data) => {
  return AxiosAuth2.get("/api/affectedAreaImage/getAffectedAreaImage?imagePath="+data, undefined, undefined, false, true);
};

const downloadImages = (data) => {
  return AxiosAuth2.post("/api/surveyImages/downloadImages", data, undefined, false, true);
};

const downloadImagesJanssen = (data) => {
  return AxiosAuth2.post("/api/janssen/surveyImages/downloadImages", data, undefined, false, true);
};

const downloadImagesJanssenNew = (data) => {
  return AxiosAuth2.post("/api/janssen/surveyImages/downloadImagesJanssen", data, undefined, false, true);
};

const downloadImagesOhsu = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/downloadImagesOhsu", data, undefined, false, true);
};

const downloadNewRashImages = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/downloadNewRashImages", data, undefined, false, true);
};

const downloadImagesNih = (data) => {
  return AxiosAuth2.post("/api/nih/surveyImage/downloadImagesNih", data, undefined, false, true);
};

const deleteZipFile = () => {
  return AxiosAuth2.get("/api/surveyImages/deleteZipFile", undefined);
};

const downloadPatientTasks = (studyId, patientId) => {
  return AxiosAuth2.get("/api/patient/downloadPatientTasks/"+studyId+"/"+patientId, undefined, undefined, false, true);
};

const deleteDirectoryFiles = () => {
  return AxiosAuth2.get("/api/patient/deleteDirectoryFiles", undefined);
};

const uploadBulkUsers = (file) => {
  return AxiosAuth2.post("/api/parade/uploadCsvFile", file, undefined);
};

const uploadPartPhoto = (data) => {
  return AxiosAuth2.post("/api/image/saveImage", data, undefined);
};

const saveImage = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/update-survey-image", data, undefined);
};

const saveRashImage = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/update-newRash-image", data, undefined);
};

const deleteParadeImage = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/deleteImage", data, undefined);
};

const deleteRashParadeImage = (data) => {
  return AxiosAuth2.post("/api/ohsu/surveyImages/deleteNewRashImage", data, undefined);
};


const downloadParticipantData= (fileName) => {
  return AxiosAuth2.get("/api/download/patientData/parade/"+fileName, undefined, undefined, false, true);
};

const downloadHealthDataNih= (fileName) => {
  return AxiosAuth2.get("/api/download/patientData/nihAppleHealth/"+fileName, undefined, undefined, false, true);
};

const downloadPatientDataNih= (fileName) => {
  return AxiosAuth2.get("/api/download/patientData/nih/"+fileName, undefined, undefined, false, true);
};

const downloadNihFlareData= (fileName) => {
  return AxiosAuth2.get("/api/download/patientData/nihFlareData/"+fileName, undefined, undefined, false, true);
};

const getNewRashTask = (userId, quatar) => {
  return AxiosAuth2.get("/api/ohsu/surveyImages/getNewRashTask/?userId="+userId+"&quatar="+quatar, undefined);
}

const getNewRashImages = (data) => {
  return AxiosAuth2.get("/api/ohsu/surveyImages/getAllNewRashImagesByDate", data, undefined);
};

export default {
  getPatientsList,
  getPatientSurveyImage,
  approvePatient,
  getApprovedPatientsList,
  getApprovedPatientsListJanssen,
  getDisapprovedPatientsList,
  getPatientById,
  getPatientByIdJanssen,
  getQuesAnsList,
  getPatientStudyImage,
  getAuditLogs,
  getDisqualifiedPatientsList,
  getTasksList,
  getInActivePatientsList,
  getPhotoGalleryByWeek,
  getPatientSelfEstimation,
  getPatientSelfEstimationJanssen,
  updateSelfEstimationScores,
  sendPendingTasks,
  deleteDuplicateTasks,
  getAffectedAreaImage,
  downloadImages,
  deleteZipFile,
  downloadPatientTasks,
  deleteDirectoryFiles,
  getJansennPhotoGalleryByWeek,
  sendPendingTasksJanssen,
  deleteDuplicateTasksJanssen,
  downloadImagesJanssen,
  getAllImagesAndScoreForPhysicianPortal,
  getJansennPhotoGalleryByWeekNew,
  downloadImagesJanssenNew,
  getJanssenPatientSurveyImage,
  getApprovedPatientsListParade,
  getInactivePatientsForOhsu,
  getPatientByIdParade,
  getAllOhsuImagesForPhysicianPortal,
  downloadImagesOhsu,
  getApprovedPatientsListNih,
  getPatientByIdNih,
  getNihPhotoGallery,
  downloadImagesNih,
  addPatientParade,
  uploadBulkUsers,
  uploadPartPhoto,
  deleteParadeImage,
  deleteRashParadeImage,
  downloadParticipantData,
  saveImage,
  getNewRashTask,
  getNewRashImages,
  saveRashImage,
  downloadNewRashImages,
  downloadHealthDataNih,
  downloadPatientDataNih,
  downloadNihFlareData
};
